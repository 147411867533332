

import { firestore } from '@/firebase';
import { USER_GETTERS } from '@/store/user/getters';
import { collection, getDoc, getDocs, orderBy, query, where, doc, addDoc } from 'firebase/firestore';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ProgramScreenCastsCard extends Vue {
    @Prop({ required: true }) userId!: string;

    screencasts = [] as any[];

    get currentUser() {
        return this.$store.getters[USER_GETTERS.ACTING_USER];
    }

    open = false;

    async fetchScreenCasts() {
        this.screencasts = [];
        const result = await getDocs(query(collection(firestore, 'content-assignments'), where('user', '==', this.userId)));
        for (const assignment of result.docs) {
            // get doc.data().contentId
            const content = await getDoc(doc(firestore, 'content-items', assignment.data().contentId));
            this.screencasts.push({
                id: assignment.id,
                title: content.data()!.title,
                completed: assignment.data().completed,
            })
        }
    }

    async mounted() {
        await this.fetchScreenCasts();
    }

    addContentAssignmentDialog = false;

    content = [] as any[];
    selectedContent = '';

    @Watch('addContentAssignmentDialog')
    async onOpenAddContentAssignmentDialog() {
        if (this.addContentAssignmentDialog) {
            const docs = await getDocs(query(collection(firestore, 'content-items')));
            this.content = docs.docs.map(doc => {
                return {
                    id: doc.id,
                    title: doc.data().title,
                    language: doc.data().language,
                }
            });
        }
    }

    async addContentAssignment() {
        await addDoc(collection(firestore, 'content-assignments'), {
            user: this.userId,
            contentId: this.selectedContent,
            completed: false,
            requestedBy: this.currentUser.id,
        });
        this.addContentAssignmentDialog = false;
        await this.fetchScreenCasts();
    }
}
